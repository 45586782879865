import state from "./state";
import { toRaw } from 'vue'

export default{
  setUser(state,user){
    state.user = user;
  },
  setToken(state,d){
    state.mtoken = d
  },
  setCategory(state,d){
    state.category = d
  },
  setSelectCategory(state,d){
    state.selectCategory = d
  },
  setSelectIndex(state,d){
    state.selectIndex = d
  },
  setCategoryId(state,d){
    state.category_id = d
  },
  setCategoryValue(state,d){
    state.category_value = d
  },
  setLists(state,d){
    state.lists = d
  },

  //临时绘制的展位
  setTempLists(state,d){
    let oldArr = JSON.parse(JSON.stringify(state.tempLists));
    let drawTempArr = []; //下一个要绘制的展位
    if(Object.prototype.toString.call(d)==='[object Array]'){
      oldArr.push.apply(oldArr,d);
      drawTempArr.push.apply(drawTempArr,d);
    }else{
      oldArr.push(d);
      drawTempArr.push(d);
    }
    state.tempLists = oldArr;
    state.drawTempLists = drawTempArr;
  },
  //删除1个临时展位
  setTempListsRemove(state,d){
    let oldArr = JSON.parse(JSON.stringify(state.tempLists));
    // oldArr.splice(state.tempLists.indexOf(d),1);
    // state.tempLists = oldArr;
    let index = oldArr.findIndex((value, index)=>{
      console.log(JSON.stringify(value));
      // console.log(JSON.stringify(toRaw(d));
      
      return JSON.stringify(value)===JSON.stringify(toRaw(d));
    })

    console.log(d)
    console.log(JSON.stringify(toRaw(d)));
    console.log(index)

    if(index!==-1){
      oldArr.splice(index,1);
      state.tempLists = oldArr;
    }
  },
  //删除所有临时展位
  setTempListsRemoveAll(state,d){
    state.tempLists = [];
  },
  //更新展位位置
  setTempListsUpdate(state,d){
    let oldArr = JSON.parse(JSON.stringify(state.tempLists));

   

    // console.log(toRaw(state.tempLists));
    // console.log(toRaw(d.old));
    // console.log(state.tempLists.indexOf(d.old));
    // oldArr.splice(state.tempLists.indexOf(d.old),1,d.new);
    // state.tempLists = oldArr;

    let index = oldArr.findIndex((value, index)=>{
      console.log(value)
      return JSON.stringify(value)===JSON.stringify(toRaw(d.old));
    })

    console.log("index: ",index);
    if(index!==-1){
      oldArr.splice(index,1,d.new);
      state.tempLists = oldArr;
    }
  },

  //设置还未绘制的临时展位
  // setDrawTempLists(state,d){
  //   let oldArr = JSON.parse(JSON.stringify(state.drawTempLists));
  //   if(Object.prototype.toString.call(d)==='[object Array]'){
  //     oldArr.push.apply(oldArr,d);
  //   }else{
  //     oldArr.push(d);
  //   }
  //   state.drawTempLists = oldArr;
  // },
  setDrawTempListsRemoveAll(state,d){
    state.drawTempLists = [];
  },

  setDatas(state,d){
    state.datas = d
  },
  setRulerIsShow(state,d){
    state.rulerIsShow = d
  },
  setGuidWidth(state,d){
    state.guidWidth = d
  },
  //设置当前选中的组
  setSelectGroup(state,d){
    state.selectGroup = d
  },
  //设置展位编辑框弹出层属性
  setBuyPopupIsShow(state,d){
    state.buyPopupIsShow = d;
  },
  //设置新增展位弹出层
  setAddPopupIsShow(state,bool){
    state.addPopupIsShow = bool;
  },
  //设置当前选中的展位
  setBuyPopupData(state,obj){
    state.buyPopupData = obj;
  },
  //设置当前绘制的展位数据
  setAddPopupData(state,obj){
    state.addPopupData = obj;
  },
  //裁剪展位弹出层
  setCropPopupIsShow(state,bool){
    state.cropPopupIsShow = bool;
  },
  //当前裁剪展位数据
  setCropPopupData(state,obj){
    state.cropPopupData = obj;
  }
}